
import Vue from "vue";
import View from "./view";
import router from '@/router'
import { bus } from '@/main'
import { Delete } from "@/repositories/global"
export default Vue.extend({
  data(){
      return{
          loading : true
      }
  },    

  props: {
    d: {
      required: true,
      type: View,
    },
  },
  methods: {
    edit() {
      const currentPath = router.currentRoute.path;
      const path = currentPath.replace("view", "edit");
      router.push(path);
    },
    remove() {
       Delete({table : this.d.table ,id : this.d.id}).then((res:any) => {
          const currentPath = router.currentRoute.path;
          const path = currentPath.replace("view", "");
          // router.push(path)
          bus.$emit('getTableData');
        })
    },
  },
  mounted(){
      this.d.find().then(()=>this.loading = false)
  }
});
